import { languageQueryInjector } from 'common/util/languageQueryInjector'
import queryInjector from 'common/util/queryInjector'
import {isOnStaging} from "./common/util/helpers";

const addPrefix = value => (value ? `/${value}` : '')
const addKeyPrefix = (element, key) => addPrefix(element[key])

export const keepQueryString = pathname => languageQueryInjector(pathname)

// User account / Wallet / Notifications / Profile:
export const activityUrl = () => '/n/notifications'
export const accountUrl = () => '/a'
export const walletUrl = () => '/p/wallet'
export const userUrl = user => `/${user.username}`
export const kycUrl = () => '/m/kyc'
export const brazilianCampainUrl = () => '/riocriptoday/'
export const adminPanelSeeUserSummaryUrl = (userId) => {
  return isOnStaging() ? `https://api-staging.futuur.com/____/reports/user_summary/${userId}/` : `https://api.futuur.com/____/reports/user_summary/${userId}/`
}

// Real money / Cash-in / Withdraw:
export const currencySelectorUrl = () => '/m/currency-selector'
export const redeemCode = () => '/m/redeem-code'
export const locationErrorUrl = () => `/p/location-not-allowed`
export const addSkrillAccountUrl = () => '/m/add-skrill-account'
export const cashInConfirmationUrl = () => '/m/cash-in/confirmation'
export const buyBitcoinInfoUrl = () => '/m/how-to-buy-bitcoin'
export const depositStatus = () => `/astropay/deposit_status/:depositId`
export const withdrawStatus = () => `/astropay/cashout_status/:merchant_cashout_id/`
export const cashInUrl = currencyId => {
  if (currencyId) {
    return '/m/cash-in?currency=' + currencyId
  }

  return '/m/cash-in'
}

export const withdrawUrl = currencyId => {
  if (currencyId) {
    return '/m/withdraw?currency=' + currencyId
  }

  return '/m/withdraw'
}

export const withdrawEmailReceivedUrl = () => {
  return '/m/withdraw/email-received'
}

export const withdrawValidationUrl = () => {
  return '/m/withdraw/validate'
}

export const recoverPassword = () => '/p/recover-password'

// Settings
export const emailUpdateUrl = () => `/p/email-update`
export const personalInfoUrl = () => `/p/personal-info-update`
export const passwordUpdateUrl = () => '/p/update-password'

// Feed / Questions / Category
export const feedUrl = () => '/'
export const betUrlById = id => `/prediction/${id}`
export const questionDetailUrlById = questionId => `/q/${questionId}`
export const questionDetailUrl = question => `/q/${question.id}${addKeyPrefix(question, 'slug')}`
export const questionHistoryUrl = question => `${questionDetailUrl(question)}/history`
export const categoryListUrl = category => `/q/category/${category.id}${addKeyPrefix(category, 'slug')}`
export const tagUrl = tag => `/q/tag/${tag}`
export const tagsListUrl = () => '/p/tags'
export const sellBetUrl = (questionId, betId) => `/m/${questionId}/sell/${betId}`
export const adminPanelEditMarketUrl = (marketId) => {
  return isOnStaging() ? `https://api-staging.futuur.com/____/wager/question/${marketId}/change/` : `https://api.futuur.com/____/wager/question/${marketId}/change/`
}

// Auth / SignUp / Confirmation
export const loginUrl = clearToken => '/l/login' + (clearToken ? '?clearToken=true' : '')
export const loginRegisterUrl = () => '/l/register'
export const loginProfileUrl = () => '/l/profile'
export const loginResetUrl = () => '/l/reset'
export const signupUrl = () => '/l/register'
export const signupFormUrl = () => '/l/signup'
export const logoutUrl = () => '/l/logout'
export const emailConfirmationRequiredUrl = () => '/p/verify-your-email'
export const setUserNameAfterSignIn = () => '/p/complete-your-profile'
export const emailConfirmedUrl = () => '/p/confirmed-email'

// Terms / Policies
export const termsUrl = () => 'https://futuur1.notion.site/Terms-of-Service-b7ea97e18b7342ae87a10d29c7e75927'
export const privacyPolicyUrl = () => 'https://futuur1.notion.site/Privacy-Policy-5555cf7f387b4de9ab280c0f0ba5a8f9'
export const contestRulesUrl = () => '/i/contest_rules'
export const docs = () => process.env.REACT_APP_URL + '/docs/'

// Other
export const communityUrl = () => process.env.REACT_APP_COMMUNITY_URL
export const notFoundUrl = () => '/i/not-found'
export const feedbackUrl = topic => (topic == null ? '/i/feedback' : `/i/feedback/?topic=${topic}`)

// Other methods
export const isLoginUrl = pathname => pathname.startsWith('/l/')
export const isLoginProfileUrl = pathname => pathname.startsWith('/l/profile')
export const isFeedPage = pathname => pathname.startsWith(feedUrl())
export const isCategoryPage = pathname => pathname.startsWith('/q/category')
export const isLocationPublic = pathname => {
  return !(
    pathname.startsWith('/account') ||
    pathname.startsWith('/settings') ||
    pathname.startsWith('/a/notifications') ||
    pathname.startsWith('/a/following')
  )
}

// Social / Leaderboard
export const leaderboardUrl = query => queryInjector('/c/leaderboard', query)
