import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { keepQueryString, feedUrl, loginRegisterUrl, tagsListUrl } from 'urls'
import { GlobeIcon, Over18Icon } from 'common/components/icons'
// import Device from 'common/util/Device'
// import SettingsList from 'settings/components/settings-list'
import { feedbackUrl } from 'urls'
import { howItWorksUrl, jobs, publicApiDocs } from 'landing/urls'
import { i18n } from 'common/locale/i18n'
import { privacyPolicyUrl, termsUrl } from 'urls'
import { useCurrentUser } from 'common/hooks'
import { selectActiveLanguage, setActiveLanguage } from 'store/app'
import { useDispatch, useSelector } from 'react-redux'
import { updateCurrentUserSettings } from 'store/account'
import './Footer.scss'
import FilterDropdown from 'common/components/filter-dropdown/FilterDropdown'
import { DiscordIcon, InstagramIcon, TwitterIcon } from 'common/components/icons/footerSocials'
import FutuurLogo from 'common/components/FutuurLogo'
import { useState } from 'react'
import classNames from 'classnames'
// import CurrencyIcon from 'common/components/currencyIcon'
import { helpCenterUrl } from 'social/urls'
import AnjouanLicensingLogo from '../../common/components/icons/AnjouanLicensingLogo'

const Footer = () => {
  const currentUser = useCurrentUser()
  const language = useSelector(selectActiveLanguage())
  const [open, setOpen] = useState()
  const dispatch = useDispatch()
  // const exchangeRate = useCurrencies()
  // const realMoneyCurrencies = exchangeRate.realMoneyCurrencies
  // const currencyList = Object.keys(realMoneyCurrencies)
  //   .map(currencyId => realMoneyCurrencies[currencyId])
  //   .filter(currency => currency.is_available_for_deposit)
  //   .sort()

  // const fiat = currencyList.filter(currency => currency.currency_type === 'fiat')
  // const crypto = currencyList.filter(currency => currency.currency_type === 'crypto')
  useEffect(() => {
    try {
      if (window && window.apg_05bd754e_a13e_467c_8c37_58905b380cff) {
        window.apg_05bd754e_a13e_467c_8c37_58905b380cff.init()
      }

      /* Anjouan Licensing */
      window.anj_48e708ff_20b3_48b4_a346_a5ab12ed8b74.init()
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    // TODO : when adding new languages this should change

    const lang = language.substring(0, 2)
    document.querySelector('html').setAttribute('lang', lang)
  }, [language])
  const onChangeLanguage = language => {
    const _language = language === 'pt-br' ? 'pt_br' : language
    dispatch(updateCurrentUserSettings({ language: _language }))
    dispatch(setActiveLanguage(language))
  }

  const languageOptions = [
    { key: 'English', text: 'English', value: 'en' },
    { key: 'Portuguese', text: 'Portuguese', value: 'pt-br' },
  ]

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__row footer__sitemap flex-col md:flex-row gap-16 md:gap-4 ">
          <div className="items-start flex flex-col gap-4">
            <Link to={feedUrl()}>
              <FutuurLogo white fill={'white'} className="navbar__logo d-block" width="100px" />
            </Link>

            <p className="text-[#EDEFED] md:max-w-80" >{i18n('footer.disclaimer')}</p>

            <div
              className={classNames('footer__Language', {
                'footer__Language--open': open,
              })}>
              <GlobeIcon width="16px" />
              <FilterDropdown
                type="list"
                value={language}
                list={languageOptions}
                highlightSelected
                arrow="outlined"
                onOpenChange={setOpen}
                onChange={onChangeLanguage}
              />
            </div>
          </div>
          <div className="flex justify-between md:justify-start gap-4 ">
            <div className="footer__section">
              <ul className="footer__link-list text-[#C2CED1]">
                <li className="footer__link text-white font-bold">
                  <a href={feedUrl()} rel="noreferrer" target="_blank">
                    Futuur
                  </a>
                </li>
                {!currentUser && (
                  <li className="footer__link">
                    <Link to={keepQueryString(loginRegisterUrl())}>{i18n('footer.signup')}</Link>
                  </li>
                )}

                <li className="footer__link">
                  <Link to={keepQueryString(howItWorksUrl())}>{i18n('footer.how_works')}</Link>
                </li>
                <li className="footer__link">
                  <a href={helpCenterUrl()} rel="noreferrer" target="_blank">
                    {i18n('footer.help_center')}
                  </a>
                </li>
                <li className="footer__link">
                  <a href={jobs()} rel="noreferrer" target="_blank">
                    {i18n('footer.career')}
                  </a>
                </li>
                <li className="footer__link">
                  <a href={publicApiDocs()} rel="noreferrer" target="_blank">
                    {i18n('footer.api_docs')}
                  </a>
                </li>
                <li className="footer__link">
                  <Link to={keepQueryString(tagsListUrl())}>Tags</Link>
                </li>
              </ul>
            </div>

            <div className="footer__section">
              <ul className="footer__link-list text-[#C2CED1]">
                <li className="footer__link  text-white font-bold">
                  <Link to={keepQueryString(feedbackUrl('message-us'))}>{i18n('feedback.form.topics.message_us')}</Link>
                </li>
                <li className="footer__link">
                  <Link to={keepQueryString(feedbackUrl('market-suggestion'))}>{i18n('footer.suggest')}</Link>
                </li>
                <li className="footer__link">
                  <Link to={keepQueryString(feedbackUrl('bug-report'))}>{i18n('footer.report')}</Link>
                </li>
                <li className="footer__link">
                  <Link to={keepQueryString(feedbackUrl('message-us'))}>{i18n('footer.contact')}</Link>
                </li>
              </ul>
            </div>
            <div className="footer__section">
              <ul className="footer__link-list text-[#C2CED1]  mb-3 md:mb-0">
                <li className="footer__link  text-white font-bold  p-2">Join our community</li>
                <li className="flex">
                  <a href="https://twitter.com/futuurHQ" target="_blank" rel="noreferrer">
                    <TwitterIcon />
                  </a>
                  <a href="https://www.instagram.com/futuurHQ" target="_blank" rel="noreferrer">
                    <InstagramIcon />
                  </a>

                  <a href="https://discord.gg/BJMX5mRZT5" target="_blank" rel="noreferrer">
                    <DiscordIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between mt-16 ">
            <span className="text-[#C2CED1] ">© 2025 Futuur Inc.</span>

            <div className="footer__verification ">
              <AnjouanLicensingLogo />

              <div
                id="apg-05bd754e-a13e-467c-8c37-58905b380cff"
                className="footer__curacao"
                data-apg-seal-id="05bd754e-a13e-467c-8c37-58905b380cff"
                data-apg-image-size="50"
                data-apg-image-type="basic-small"
              />
              <Over18Icon className="footer__over18icon" width="32px" />
            </div>
          </div>

          <p
            className="text-[#C2CED1] !text-xs !leading-3 "
            dangerouslySetInnerHTML={{ __html: i18n('footer.psp_disclamer') }}
          />

          <div className="flex gap-4 text-sm">
            <a href={termsUrl()} rel="noreferrer" target="_blank">
              {i18n('footer.terms')}
            </a>
            <span>·</span>
            <a href={privacyPolicyUrl()} rel="noreferrer" target="_blank">
              {i18n('footer.privacy')}
            </a>
          </div>
        </div>

        {/* <div className="footer__section footer__sitemap footer__sitemap--currencies">
          <ul className="footer__currencies_list">
            {crypto.map(currency => (
              <li key={'footer-crypto-currencies-' + currency.display_code} className=" footer__currency">
                <CurrencyIcon currency={currency} width="26px" />
              </li>
            ))}
          </ul>
          <ul className="footer__currencies_list">
            {fiat.map(currency => (
              <li
                className=" footer__currency"
                style={{ marginLeft: '32px' }}
                key={'footer-fiat-currencies-' + currency.display_code}>
                <CurrencyIcon currency={currency} width="26px" />

                <span>{currency.display_code}</span>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
