import React from 'react'

const TwitterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.33333 1.33334H0.666668L6.17333 8.67668L0.966668 14.6667H2.73333L6.992 9.76734L10.6667 14.6667H15.3333L9.59467 7.01468L14.5333 1.33334H12.7667L8.776 5.92401L5.33333 1.33334ZM11.3333 13.3333L3.33333 2.66668H4.66667L12.6667 13.3333H11.3333Z"
        fill="#C2CED1"
      />
    </svg>
  )
}

export default TwitterIcon
