import React, { Suspense, lazy, useContext, useEffect, useState, useRef } from 'react'
import QuestionFooter from '../question-footer'
import { isBetSelectable } from 'common/lib/bets'
import { formatDate, formatDistance } from 'common/util/dateFormater'
import questionService from 'api/question'
import ClockIcon from 'common/components/icons/clock'
import parseISO from 'date-fns/parseISO'
import MarketContext from 'app/context/MarketContext'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { selectMarketById } from 'store/markets'
import SuspensePanel from 'common/components/suspense'
import { i18n } from 'common/locale/i18n'
import {useActiveCurrency, useCurrencies, useCurrentUser} from 'common/hooks'
import {keepQueryString, loginRegisterUrl, questionDetailUrl} from 'urls'
import { Link, useNavigate } from 'react-router-dom'
import { compareDesc } from 'date-fns'
import { RealMoneyIcon } from 'common/components/icons'
import { getWagerSelected } from 'store/wagers'
import 'portfolio/components/bet/Bet.scss'
import useIsMobileSize from "common/hooks/useIsMobileSize";
const OutcomeList = lazy(() => import('questions/components/outcome-list'))
const QuestionHeader = lazy(() => import('questions/components/question-header'))
const UnavailableMarket = lazy(() => import('app/components/unavailable-market'))
const Bet = lazy(() => import('portfolio/components/bet'))

const MarketCard = ({
  categories,
  showSelectedOutcome,
  maxVisibleOutcome = 3,
  isRelatedMarket = false,
  currentOutcomeSelection,
  onOutcomeSelected = () => {},
  showSummary,
  inlineBehavior,
  className = '',
  inMarketPage,
  noAnimation = false,
  setMarketSelected,
  id,
  ...props
}) => {
  const currencies = useCurrencies().currencies
  const { marketEnable } = useContext(MarketContext)
  const questionId = (props.question && props.question.id) || props.questionId
  const question = props.question || useSelector(selectMarketById(questionId))
  const [selectedOutcome, setSelectedOutcome] = useState(currentOutcomeSelection)
  const activeCurrency = useActiveCurrency()
  const isRealMoney = activeCurrency && activeCurrency?.id !== currencies.OOM.id
  const availableMarket = (question && !isRealMoney) || (isRealMoney && question.real_currency_available)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobileSize = useIsMobileSize()
  const user = useCurrentUser()

  const betList = props.betList || question.wagers || []
  useEffect(() => {
    setSelectedOutcome(currentOutcomeSelection)
  }, [currentOutcomeSelection])

  const goToQuestionDetails = () => {
    navigate({ pathname: questionDetailUrl(question), state: { outcome: selectedOutcome } })
  }

  useEffect(() => {
    if (!inlineBehavior && selectedOutcome) goToQuestionDetails()
  }, [selectedOutcome])

  /* Dynamically measure card width*/
  const cardRef = useRef(null)
  const [cardWidth, setCardWidth] = useState(0)

  useEffect(() => {
    const updateCardWidth = () => {
      if (cardRef.current) {
        setCardWidth(cardRef.current.offsetWidth)
      }
    }

    // Initial width check and add listener for resizing
    updateCardWidth()
    window.addEventListener('resize', updateCardWidth)

    return () => window.removeEventListener('resize', updateCardWidth)
  }, [])

  const activeFirst = (acc, element) => {
    if (element.status === 'p') {
      return [element, ...acc]
    }
    return [...acc, element]
  }
  const renderBetList = betList => {
    const openBetList = betList.filter(bet => bet.status !== 's')
    const soldBetList = betList.filter(bet => bet.status === 's')
    return (
      <>
        <div
          className={classNames('question-card__section--wide question-card__bet-status', {
            'rounded-b-lg': !inMarketPage,
          })}>
          {inMarketPage && (
            <div className="mb-2">
              <h4 className="question-card__title bet_status_title">{i18n('question_card.bets_on_the_market')}</h4>
            </div>
          )}
          {openBetList
            .sort((bet1, bet2) => compareDesc(new Date(bet1.last_update), new Date(bet2.last_update)))
            .reduce(activeFirst, [])
            .map(wager => (
              <div key={`market--bet-${wager.id}`} className="question-card__bet-entry">
                <Bet
                  market={question}
                  bet={wager}
                  onSell={() => {
                    dispatch(getWagerSelected({ ...wager, _diff: Math.random() }))
                    if (setMarketSelected) setMarketSelected(question)
                  }}
                  onBetInfoClick={() => navigate(questionDetailUrl(question))}
                />
              </div>
            ))}

          {inMarketPage &&
            soldBetList
              .sort((bet1, bet2) => compareDesc(new Date(bet1.last_update), new Date(bet2.last_update)))
              .reduce(activeFirst, [])
              .map(wager => (
                <div key={`market--bet-${wager.id}`} className="question-card__bet-entry">
                  <Bet market={question} bet={wager} onBetInfoClick={() => navigate(questionDetailUrl(question))} />
                </div>
              ))}

          {!inMarketPage && soldBetList.length > 0 && (
            <div className="question-card__bet-entry">
              <div className="bet bet--soldBetList ">
                <div className="d-flex" style={{ alignItems: 'center', gap: '8px', justifyContent: 'space-between' }}>
                  <RealMoneyIcon width="20px" />
                  <span>
                    {soldBetList.length} {i18n('question_card.positions_in')}
                  </span>
                </div>
                <Link to={keepQueryString(questionDetailUrl(question))} className="link">
                  {i18n('question_card.view_bets')}
                </Link>
              </div>
            </div>
          )}
        </div>
      </>
    )
  }

  // const renderSellBet = bet => {
  //   return (
  //     <Suspense fallback={<SuspensePanel />}>
  //       <ViewSellBet
  //         betId={bet.id}
  //         questionId={questionId}
  //         onSale={async () => {
  //           await dispatch(sellBet(bet.id, bet.shares, bet.amount_on_sell, marketType, currentRouteName))
  //           await dispatch(resetBetHistory({ questionId }))
  //           loadNextLatestBets(true)
  //         }}
  //         onSellClose={() => setBetToSell(null)}
  //       />
  //     </Suspense>
  //   )
  // }
  const onOutcomeClick = outcome => {
    // if (Device.isMobile) {
    //   if (outcome && outcome.id) {
    //     navigate(questionBetUrl(question, outcome.id), {
    //       scrollRestoration: question.id,
    //       returnUrl: location.pathname,
    //     })
    //   } else {
    //     goToQuestionDetails()
    //   }
    //   return
    // }

    if(isMobileSize && !user)
    {
      navigate(loginRegisterUrl())
    }
    else
    {
      if (outcome && outcome.id) {
        setSelectedOutcome(outcome)
        onOutcomeSelected(outcome)
      } else {
        setSelectedOutcome(null)
        onOutcomeSelected(null)
      }
    }
  }

  const renderQuestionHeader = () => {
    let questionSummary

    if (questionService.isWaitingForResults(question)) {
      questionSummary = renderWaitingForResultsMessage()
    }

    if (questionService.isCancelled(question)) {
      questionSummary = renderCancelledInfoMessage(question)
    }

    if (questionService.isResolved(question)) {
      questionSummary = renderResolvedQuestionLabel(question)
    }

    if (!questionSummary && questionService.hasUpcomingEvent(question)) {
      questionSummary = renderEventStartDateLabel(question)
    }

    return (
      <Suspense fallback={<SuspensePanel />}>
        <QuestionHeader
          showBrierScore={showSummary && question.complete}
          categories={categories}
          marketEnabled={marketEnable}
          question={question}
          inMarketPage={inMarketPage}
          marketCardWidth={cardWidth}
        />
        {/* {questionSummary} */}
      </Suspense>
    )
  }

  const getBetForActiveCurrency = betList => betList.find(bet => bet.currency === activeCurrency?.id)

  const renderWaitingForResultsMessage = () => {
    return <div className="question-card__results-label">{i18n('question_card.waiting_result')}</div>
  }

  const renderCancelledInfoMessage = () => {
    return <div className="question-card__cancelled-label">{i18n('question_card.date.cancelled')}</div>
  }

  const renderResolvedQuestionLabel = question => {
    return (
      <div className="question-card__resolved-label">
        {formatDate(parseISO(question.resolve_date))}
        <br />
        <strong>{i18n('question_card.date.resolved')}</strong>
      </div>
    )
  }

  const renderEventStartDateLabel = question => {
    const startDateTS = Date.parse(question.event_start_date)
    return (
      <div className="question-card__start-date-label">
        <ClockIcon fill="#1591ed" width="15px" /> {i18n('question_card.live_in')}{' '}
        {formatDistance(startDateTS, Date.now())}
      </div>
    )
  }

  const renderUnavailableMessage = () => {
    return (
      <div className="question-card__unavailable-message">
        <Suspense fallback={<SuspensePanel />}>
          <UnavailableMarket />
        </Suspense>
      </div>
    )
  }

  const renderOutcomeList = betList => {
    if (!question) return
    const bet = getBetForActiveCurrency(betList)
    const outcomeClickable = isBetSelectable(bet) && availableMarket
    return (
      <div className="question-card__outcome-list">
        <OutcomeList
          maxVisibleOutcome={maxVisibleOutcome}
          outcomes={question.outcomes}
          question={question}
          currencyId={activeCurrency?.id}
          selectedId={outcomeClickable && showSelectedOutcome && selectedOutcome && selectedOutcome.id}
          bet={bet}
          wager={bet && bet.outcome}
          onItemClick={outcomeClickable ? onOutcomeClick : null}
          isRelatedMarket={isRelatedMarket}
          noAnimation={noAnimation}
        />
      </div>
    )
  }

  const questionInfoClassList = classNames('question-card__section', 'question-card__main', className, {
    'rounded-t-lg': !inMarketPage,
    'rounded-b-lg': !inMarketPage && betList.length === 0,
  })
  const showBetStatus = props.showBetStatus && betList.length && betList.length > 0

  if (question == null) {
    return null
  }

  return (
    <>
      <div className={questionInfoClassList} id={id} ref={cardRef}>
        <div>
          <div>
            {renderQuestionHeader()}
            {renderOutcomeList(betList)}
            <QuestionFooter question={question} inMarketPage={inMarketPage} />
            {!availableMarket && renderUnavailableMessage()}
          </div>
        </div>
      </div>

      {Boolean(showBetStatus) && renderBetList(betList)}
    </>
  )
}
export default MarketCard
