import React, { useEffect } from 'react'
import { i18n } from 'common/locale/i18n'
import { DropdownMenu, MenuAction, MenuLink } from 'common/components/dropdown-menu'
import {
  CogIcon,
  DotsIcon,
  EmailIcon,
  ExitIcon,
  FutuurIcon,
  InfoBalloonIcon,
  Lune,
  PaperAirplaneIcon,
  ParchmentIcon,
  PasswordIcon,
  PersonIcon,
  RedeemIcon,
  WalletIcon,
} from 'common/components/icons'
import { feedbackUrl, keepQueryString, privacyPolicyUrl, redeemCode, termsUrl, userUrl, walletUrl } from 'urls'
import { howItWorksUrl } from 'landing/urls'
import { settingsUrl } from 'profile/urls'

import './NavbarMenu.scss'
import { ToggleControl } from 'common/components/form-controls'
import { useAppTheme } from 'common/hooks'
import { helpCenterUrl } from 'social/urls'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminMode, selectInfo, selectPageLayout, setActiveCurrency } from 'store/app'
import { logout, selectUserFromAllowedCountry } from 'store/account'
import constants from 'app/constants'

const NavbarMenu = props => {
  const dispatch = useDispatch()
  const { getTheme, toggleTheme } = useAppTheme()
  const { adminMode } = useSelector(selectPageLayout)
  const info = useSelector(selectInfo)
  const fromAllowedCountry = useSelector(selectUserFromAllowedCountry())
  const { hasUserSession, me } = props

  const handleLogout = async () => {
    await dispatch(logout())
    const defaultCurrency = fromAllowedCountry ? constants.REAL_MONEY_PLACEHOLDER : constants.PLAY_MONEY
    await dispatch(setActiveCurrency(defaultCurrency))

    location.reload()
  }

  useEffect(() => {
    if (!me) return
    if (me && !me.is_staff) {
      dispatch(getAdminMode(false))
      localStorage.removeItem('adminMode')
    }
    const mode = localStorage.getItem('adminMode')
    if (adminMode === null && !mode) return
    else if (adminMode === null) dispatch(getAdminMode(mode === 'true'))
    else if (adminMode !== null) localStorage.setItem('adminMode', adminMode)
  }, [adminMode, me])

  const renderIcon = () => {
    return (
      <div className="navbar-menu__dots">
        <DotsIcon width="20" height="20" fillcolor={'var(--color-Neutral-6)'} />
      </div>
    )
  }
  const myPorfile = () => {
    const userName = _me => {
      var UN = _me.username
      if (_me.first_name) UN = _me.first_name
      if (_me.last_name) UN = UN + ' ' + _me.last_name
      return UN
    }
    return (
      <div className="navbar-menu__myProfile">
        <img
          src={me.picture}
          className="navbar-menu__myProfile__avatar"
          alt={me.username + "'s picture"}
          title={me.username + "'s picture"}
        />
        <div>
          <div className="navbar-menu__myProfile__name">{userName(me)}</div>
          <div className="navbar-menu__myProfile__username">@{me.username}</div>
          <div className="navbar-menu__myProfile__link">
            <a href={keepQueryString(userUrl(me))} className="link">
              {i18n('navbar_menu.my_profile')}
            </a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <DropdownMenu renderIcon={renderIcon} alwaysKeepHidden={props.hideSubmenu} me={me}>
      {hasUserSession && (
        <>
          {myPorfile()}

          <MenuLink
            icon={<WalletIcon width="18px" color="currentColor" />}
            text={i18n('portfolio.view_wallet')}
            url={keepQueryString(walletUrl())}
            hidden={!hasUserSession}
          />
          <MenuLink
            icon={<CogIcon fillcolor="currentColor" />}
            text={i18n('navbar_menu.settings')}
            url={keepQueryString(settingsUrl())}
            hidden={!hasUserSession}
          />
        </>
      )}
      <div className="menu-item menu-item--noHover" onClick={toggleTheme}>
        <span className="menu-item__icon">
          <Lune width="24px" height="24px" fillcolor="currentColor" />
        </span>
        {getTheme() === 'dark' ? 'Dark mode' : 'Light mode'}
        <div style={{ marginLeft: 'auto' }}>
          <ToggleControl active={getTheme() === 'dark'} />
        </div>
      </div>
      {me && me.is_staff && (
        <div
          className="menu-item menu-item--noHover"
          onClick={() => {
            if (adminMode) {
              dispatch(getAdminMode(false))
            } else dispatch(getAdminMode('true'))
          }}>
          <span className="menu-item__icon">
            <PersonIcon width="24px" height="24px" fillcolor="currentColor" />
          </span>
          Staff mode
          <div style={{ marginLeft: 'auto' }}>
            <ToggleControl active={adminMode} />
          </div>
        </div>
      )}
      <MenuLink
        icon={<FutuurIcon width="10px" fillcolor="currentColor" />}
        text={i18n('footer.how_works')}
        url={keepQueryString(howItWorksUrl())}
      />
      <MenuLink
        icon={<EmailIcon width="18px" />}
        text={i18n('footer.contact')}
        url={keepQueryString(feedbackUrl('message-us'))}
      />
      <MenuLink
        icon={<PaperAirplaneIcon width="16px" />}
        text={i18n('footer.suggest')}
        url={keepQueryString(feedbackUrl('market-suggestion'))}
      />
      {me && <MenuLink icon={<RedeemIcon width="16px" heigth="16px" />} text={'Redeem a code'} url={redeemCode()} />}
      <MenuLink
        icon={<PasswordIcon />}
        text={i18n('footer.privacy')}
        url={keepQueryString(privacyPolicyUrl())}
        target="_blank"
      />
      <MenuLink
        icon={<ParchmentIcon fillcolor="currentColor" />}
        text={i18n('footer.terms')}
        url={keepQueryString(termsUrl())}
        target="_blank"
      />

      <MenuLink
        icon={<InfoBalloonIcon fillcolor="currentColor" />}
        text={i18n('footer.help_center')}
        url={helpCenterUrl()}
      />
      {hasUserSession && (
        <MenuAction icon={<ExitIcon />} text={i18n('settings.logout')} action={handleLogout} hidden={!hasUserSession} />
      )}

      {me && me.is_staff && (
        <MenuLink icon={<FutuurIcon fillcolor="currentColor" />} text={'v.' + info?.info?.WEBAPP_LAST_COMMIT} />
      )}
    </DropdownMenu>
  )
}

export default NavbarMenu
