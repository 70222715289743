import React, {useEffect, useState} from 'react'
import {CrossIcon, MagnifyIcon} from 'common/components/icons'
import './SearchBox.scss'

const SearchBox = ({
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onEnter = () => {},
  onClear = () => {},
  initialValue = '',
  placeholder,
  id,
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    onChange(value)
  }, [value])

  const handleClear = () => {
    setValue('')
    onClear()
  }

  const handleChange = event => {
    onFocus()
    setValue(event.target.value)
  }

  const handleFocus = () => {
    onFocus()
  }

  const handleBlur = () => {
    onBlur()
  }

  const handleKeyUp = event => {

    if (event.keyCode === 13 && onEnter)
    {
      onEnter(value)
    }
    else
    {
      handleChange(event)
    }
  }

  return (
    <div className="search-box relative">
      <input
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        onKeyUpCapture={handleKeyUp}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onPaste={handleChange}
        value={value}
        id={id}
        className="bg-Neutral-1"
      />
      {
        value && (
          <div className="w-[55px] mr-16 md:!mr-[55px] absolute right-0 flex justify-content-center items-center">
            <CrossIcon onClick={handleClear} width="10px" style={{ cursor: 'pointer' }}/>
          </div>
        )
      }
        <MagnifyIcon onClick={onEnter} width="14px"  className="cursor-pointer"/>
    </div>
  )
}

export default SearchBox
