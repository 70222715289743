import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import Button from 'common/ui/button'
import './ActionSheetLayout.scss'
import { CrossIcon } from 'common/components/icons'

const ActionSheetLayout = ({
  className,
  containerClass = '',
  title,
  action,
  content,
  onButtonClick,
  buttonDisabled,
  showCloseButton,
  onClose,
  buttonLoading,
  hasHeightOverflow,
  style,
  footer,
  headerClassList,
}) => {
  const buttonClassName = className + '-button'
  const actionSheet = useRef()
  const [heightOverflow, setHeightOverflow] = useState(hasHeightOverflow)
  useEffect(() => {
    if (hasHeightOverflow) return

    window.addEventListener('resize', verifiyHeightOverflow)
    verifiyHeightOverflow()
    return () => {
      window.removeEventListener('resize', verifiyHeightOverflow)
    }
  }, [])

  const verifiyHeightOverflow = () => {
    if (actionSheet.current) {
      const rect = actionSheet.current.getBoundingClientRect()
      setHeightOverflow(rect.height > window.innerHeight * 0.9)
    }
  }

  const classList = classnames('action-sheet', className, {
    'action-sheet--not-centered': heightOverflow,
  })
  return (
    <div ref={actionSheet} className={classList} style={style}>
      {(title || showCloseButton) && (
        <header className={`action-sheet__header ${headerClassList}`}>
          {title}
          {showCloseButton && (
            <div className="action-sheet__close-button" onClick={onClose}>
              <CrossIcon width="1em" />
            </div>
          )}
        </header>
      )}
      <article className={'action-sheet__container ' + containerClass}>
        {content && <div className="action-sheet__content">{content}</div>}
        {action && (
          <Button
            className={buttonClassName}
            loading={buttonLoading}
            disabled={buttonDisabled || buttonLoading}
            onClick={onButtonClick}
            type="solid"
            size="large">
            {action}
          </Button>
        )}
        {footer}
      </article>
    </div>
  )
}

export default ActionSheetLayout
