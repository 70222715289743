import React from 'react'

const DiscordIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M12.8454 3.55189C11.9588 3.13856 10.9988 2.83856 9.99878 2.66522C9.99001 2.66494 9.98129 2.66659 9.97322 2.67004C9.96516 2.6735 9.95795 2.67868 9.95211 2.68522C9.83211 2.90522 9.69211 3.19189 9.59877 3.41189C8.53811 3.25189 7.45944 3.25189 6.39878 3.41189C6.30544 3.18522 6.16544 2.90522 6.03878 2.68522C6.03211 2.67189 6.01211 2.66522 5.99211 2.66522C4.99211 2.83856 4.03878 3.13856 3.14544 3.55189C3.13878 3.55189 3.13211 3.55856 3.12544 3.56522C1.31211 6.27856 0.812109 8.91855 1.05878 11.5319C1.05878 11.5452 1.06544 11.5586 1.07878 11.5652C2.27878 12.4452 3.43211 12.9786 4.57211 13.3319C4.59211 13.3386 4.61211 13.3319 4.61878 13.3186C4.88544 12.9519 5.12544 12.5652 5.33211 12.1586C5.34544 12.1319 5.33211 12.1052 5.30544 12.0986C4.92544 11.9519 4.56544 11.7786 4.21211 11.5786C4.18544 11.5652 4.18544 11.5252 4.20544 11.5052C4.27878 11.4519 4.35211 11.3919 4.42544 11.3386C4.43878 11.3252 4.45878 11.3252 4.47211 11.3319C6.76544 12.3786 9.23878 12.3786 11.5054 11.3319C11.5188 11.3252 11.5388 11.3252 11.5521 11.3386C11.6254 11.3986 11.6988 11.4519 11.7721 11.5119C11.7988 11.5319 11.7988 11.5719 11.7654 11.5852C11.4188 11.7919 11.0521 11.9586 10.6721 12.1052C10.6454 12.1119 10.6388 12.1452 10.6454 12.1652C10.8588 12.5719 11.0988 12.9586 11.3588 13.3252C11.3788 13.3319 11.3988 13.3386 11.4188 13.3319C12.5654 12.9786 13.7188 12.4452 14.9188 11.5652C14.9321 11.5586 14.9388 11.5452 14.9388 11.5319C15.2321 8.51189 14.4521 5.89189 12.8721 3.56522C12.8654 3.55856 12.8588 3.55189 12.8454 3.55189ZM5.67878 9.93855C4.99211 9.93855 4.41878 9.30522 4.41878 8.52522C4.41878 7.74522 4.97878 7.11189 5.67878 7.11189C6.38544 7.11189 6.94544 7.75189 6.93878 8.52522C6.93878 9.30522 6.37878 9.93855 5.67878 9.93855ZM10.3254 9.93855C9.63878 9.93855 9.06544 9.30522 9.06544 8.52522C9.06544 7.74522 9.62544 7.11189 10.3254 7.11189C11.0321 7.11189 11.5921 7.75189 11.5854 8.52522C11.5854 9.30522 11.0321 9.93855 10.3254 9.93855Z"
        fill="#C2CED1"
      />
    </svg>
  )
}

export default DiscordIcon
