import Sentry from 'app/sentry/Sentry'
import constants from 'app/constants'
import createFormData from 'common/util/createFormData'
import requestService from 'common/services/request'
import mixpanelService from 'app/analytics/Mixpanel'
import analyticsService from 'common/services/analytics'
import { createSlice } from '@reduxjs/toolkit'
import { userIsAnonymous } from 'api/auth'
import { storeCredential, getCredential, deleteCredential } from 'api/auth'
import { parseActiveCurrency, setActiveCurrency, setActiveLanguage, storeActiveCurrency } from 'store/app'
import Device from 'common/util/Device'
import { toastService } from 'api/toast'
import { addParamsToGTM } from 'app/analytics'

const initialState = {
  currentUser: { status: constants.IDLE, data: null, error: null },
  session: { status: constants.IDLE, data: null, error: null },
  allowedCountry: { status: constants.IDLE, data: null, error: null },
  walletAddresses: { status: {}, data: {}, error: null },
  deposit: { status: constants.IDLE, data: null, error: null },
  withdraw: { status: constants.IDLE, data: null, error: null },
  cancelWithdraw: { status: constants.IDLE, data: null, error: null },
  resendWithdrawMail: { status: constants.IDLE, data: null, error: null },
  updateMissingInfo: { status: constants.IDLE, data: null, error: null },
  currencyPropties: { currencies: {}, blockchains: {} },
  publicApiKeys: { status: constants.IDLE, data: null, error: null },
  twoFactorAuth: { status: constants.IDLE, data: null, error: null },
  redeemCode: { status: constants.IDLE, data: null, error: null },
  financeSummary: { status: constants.IDLE, data: null, error: null },
  delete_account: {
    status: constants.IDLE,
    error: null,
  },
}

const getAllABTests = () => {
  return JSON.parse(localStorage.getItem('abTests')) || {}
}
export const getAllCampains = () => JSON.parse(localStorage.getItem('campains') || '[]')

// Reducers / Slices
const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    getCurrentUserStart: state => {
      state.currentUser.status = constants.LOADING
    },

    currentUserSuccess: (state, action) => {
      state.currentUser.status = constants.COMPLETE
      state.currentUser.data = action.payload
    },

    currentUserError: (state, action) => {
      state.currentUser.status = constants.ERROR
      state.currentUser.error = action.payload
    },

    createCurrentUserStart: state => {
      state.currentUser.status = constants.SAVING
    },

    changeCurrentUserStart: state => {
      state.currentUser.status = constants.SAVING
    },
    requestTwoFactorAuthStart: state => {
      state.twoFactorAuth.status = constants.LOADING
    },
    requestTwoFactorAuthError: (state, action) => {
      state.twoFactorAuth.status = constants.ERROR
      state.twoFactorAuth.error = action.payload
    },
    requestTwoFactorAuthSuccess: state => {
      state.twoFactorAuth.status = constants.COMPLETE
    },
    resetTwoFactorAuth: state => {
      state.twoFactorAuth.status = constants.IDLE
      state.twoFactorAuth.error = null
      state.twoFactorAuth.data = null
    },
    requestPublicApiKeysStart: state => {
      state.publicApiKeys.status = constants.LOADING
    },
    requestPublicApiKeysError: (state, action) => {
      state.publicApiKeys.status = constants.ERROR
      state.publicApiKeys.error = action.payload
    },
    requestPublicApiKeysAccessSuccess: state => {
      state.publicApiKeys.status = constants.COMPLETE
    },
    requestPublicApiKeysSuccess: (state, action) => {
      state.publicApiKeys.status = constants.COMPLETE
      state.publicApiKeys.data = action.payload
    },
    setCurrentUserSettings: (state, action) => {
      if (state.currentUser.data && state.currentUser.data.settings) {
        state.currentUser.data.settings = { ...state.currentUser.data.settings, ...action.payload }
      }
    },

    resetCurrentUserError: state => {
      state.currentUser.status = constants.IDLE
      state.currentUser.error = null
    },

    sessionStart: state => {
      state.session.status = constants.LOADING
    },

    sessionSuccess: (state, action) => {
      state.session.status = constants.COMPLETE
      state.session.data = action.payload
    },

    sessionError: (state, action) => {
      state.session.status = constants.ERROR
      state.session.error = { status: action.payload, ...action.payload }
    },

    financeSummaryStart: state => {
      state.financeSummary.status = constants.LOADING
    },

    financeSummarySuccess: (state, action) => {
      state.financeSummary.status = constants.COMPLETE
      state.financeSummary.data = action.payload
    },

    financeSummaryError: (state, action) => {
      state.financeSummary.status = constants.ERROR
      state.financeSummary.error = { status: action.payload, ...action.payload }
    },
    redeemCodeStart: state => {
      state.redeemCode.status = constants.LOADING
    },

    redeemCodeSuccess: (state, action) => {
      state.redeemCode.status = constants.COMPLETE
      state.redeemCode.data = action.payload
    },

    redeemCodeError: (state, action) => {
      state.redeemCode.status = constants.ERROR
      state.redeemCode.error = { status: action.payload, ...action.payload }
    },
    clearRedeemCode: state => {
      state.redeemCode.status = constants.IDLE
      state.redeemCode.data = null
      state.redeemCode.error = null
    },

    setAllowedCountryStart: state => {
      state.allowedCountry.status = constants.LOADING
    },
    setAllowedCountrySuccess: (state, action) => {
      state.allowedCountry.data = action.payload
      state.allowedCountry.status = constants.COMPLETE
    },

    resetSessionError: state => {
      state.session.status = constants.IDLE
      state.session.error = null
    },

    resetCurrentUser: state => {
      state.currentUser = { ...initialState.currentUser }
      state.session = { ...initialState.session }
      state.walletAddresses = { ...initialState.walletAddresses }
      state.withdraw = { ...initialState.withdraw }
    },

    getWalletAddressLoading: (state, action) => {
      const { currencyId } = action.payload
      state.walletAddresses.status[currencyId] = constants.LOADING
    },

    getWalletAddressSuccess: (state, action) => {
      const { currencyId, address } = action.payload
      state.walletAddresses.status[currencyId] = constants.COMPLETE
      state.walletAddresses.data[currencyId] = address
    },

    getWalletAddressError: (state, action) => {
      const { currencyId, error } = action.payload
      state.walletAddresses.status[currencyId] = constants.ERROR
      state.walletAddresses.error[currencyId] = error
    },
    clearAddress: state => {
      state.walletAddresses = { ...initialState.walletAddresses }
    },

    requestDepositStart: state => {
      state.deposit.status = constants.LOADING
    },

    requestDepositSuccess: (state, action) => {
      state.deposit.status = constants.COMPLETE
      state.deposit.data = action.payload
    },

    requestDepositError: (state, action) => {
      state.deposit.status = constants.ERROR
      state.deposit.error = action.payload
    },
    requestDepositClear: state => {
      state.deposit.status = constants.IDLE
      state.deposit.data = null
      state.deposit.error = null
    },

    requestWithdrawStart: state => {
      state.withdraw.status = constants.LOADING
    },

    requestWithdrawSuccess: (state, action) => {
      state.withdraw.status = constants.COMPLETE
      state.withdraw.data = action.payload
    },

    requestWithdrawError: (state, action) => {
      state.withdraw.status = constants.ERROR
      state.withdraw.error = action.payload
    },
    cancelWithdrawStart: state => {
      state.cancelWithdraw.status = constants.LOADING
    },

    cancelWithdrawSuccess: (state, action) => {
      state.cancelWithdraw.status = constants.COMPLETE
      state.cancelWithdraw.data = action.payload
    },

    cancelWithdrawError: (state, action) => {
      state.cancelWithdraw.status = constants.ERROR
      state.cancelWithdraw.error = action.payload
    },
    requestWithdrawReset: state => {
      state.withdraw = { status: constants.IDLE, data: null, error: null }
    },

    cancelWithdrawReset: state => {
      state.cancelWithdraw.status = constants.IDLE
      state.cancelWithdraw.error = null
      state.cancelWithdraw.data = null
    },

    resendWithdrawMailStart: state => {
      state.resendWithdrawMail.status = constants.LOADING
    },

    resendWithdrawMailSuccess: (state, action) => {
      state.resendWithdrawMail.status = constants.COMPLETE
      state.resendWithdrawMail.data = action.payload
    },

    resendWithdrawMailError: (state, action) => {
      state.resendWithdrawMail.status = constants.ERROR
      state.resendWithdrawMail.error = action.payload
    },
    resendWithdrawMailReset: state => {
      state.resendWithdrawMail.status = constants.IDLE
      state.resendWithdrawMail.error = null
      state.resendWithdrawMail.data = null
    },

    updateMissingInfoStart: state => {
      state.updateMissingInfo.status = constants.LOADING
    },

    updateMissingInfoSuccess: (state, action) => {
      state.updateMissingInfo.status = constants.COMPLETE
      state.updateMissingInfo.data = action.payload
    },

    updateMissingInfoError: (state, action) => {
      state.updateMissingInfo.status = constants.ERROR
      state.updateMissingInfo.error = action.payload
    },

    setCurrencyPropties: (state, action) => {
      state.currencyPropties = action.payload
    },
    setUserDocumentOnDeposit: (state, action) => {
      const { CPF } = action.payload
      state.currentUser.data.documentOnDeposit = CPF
    },
    setUserDocumentOnWithdraw: (state, action) => {
      const { CPF } = action.payload
      state.currentUser.data.documentOnWithdraw = CPF
    },

    deleteAccountStart: state => {
      state.delete_account.status = constants.LOADING
    },
    deleteAccountSuccess: state => {
      state.delete_account.status = constants.COMPLETE
    },
    deleteAccountError: (state, action) => {
      state.delete_account.error = action.payload
      state.delete_account.status = constants.ERROR
    },
  },
})

export const {
  getCurrentUserStart,
  currentUserSuccess,
  currentUserError,
  resetCurrentUserError,
  resetCurrentUser,
  changeCurrentUserStart,
  createCurrentUserStart,
  deleteAccountStart,
  deleteAccountSuccess,
  deleteAccountError,
} = account.actions
export const {
  requestPublicApiKeysStart,
  requestPublicApiKeysError,
  requestPublicApiKeysAccessSuccess,
  requestPublicApiKeysSuccess,
} = account.actions
export const { requestTwoFactorAuthStart, requestTwoFactorAuthError, requestTwoFactorAuthSuccess, resetTwoFactorAuth } =
  account.actions
export const { sessionStart, sessionSuccess, sessionError, resetSessionError } = account.actions
export const { redeemCodeStart, redeemCodeSuccess, redeemCodeError, clearRedeemCode } = account.actions
export const { financeSummaryStart, financeSummarySuccess, financeSummaryError } = account.actions
export const { setCurrentUserSettings, setAllowedCountrySuccess, setAllowedCountryStart } = account.actions
export const { getWalletAddressLoading, getWalletAddressSuccess, getWalletAddressError, clearAddress } = account.actions
export const {
  getAstropayResponseLoading,
  getAstropayResponseSuccess,
  getAstropayResponseError,
  clearAstropayResponse,
} = account.actions
export const { getAstropayDepositStatusLoading, getAstropayDepositStatusSuccess, getAstropayDepositStatusError } =
  account.actions
export const { requestDepositStart, requestDepositSuccess, requestDepositError, requestDepositClear } = account.actions
export const { requestWithdrawStart, requestWithdrawSuccess, requestWithdrawError, requestWithdrawReset } =
  account.actions
export const { cancelWithdrawStart, cancelWithdrawSuccess, cancelWithdrawError, cancelWithdrawReset } = account.actions
export const { resendWithdrawMailStart, resendWithdrawMailSuccess, resendWithdrawMailError, resendWithdrawMailReset } =
  account.actions
export const { updateMissingInfoStart, updateMissingInfoSuccess, updateMissingInfoError } = account.actions
export const { setCurrencyPropties } = account.actions
export const { setUserDocumentOnDeposit, setUserDocumentOnWithdraw } = account.actions
export default account.reducer

// Selectors:
// ? Current User
export const selectCurrentUser = state => state.account.currentUser.data
export const selectCurrentUserStatus = state => state.account.currentUser.status
export const selectCurrentUserErrors = state => state.account.currentUser.error
// ? two factor auth
export const selectTwoFactorAuth = state => state.account.twoFactorAuth.data
export const selectTwoFactorAuthStatus = state => state.account.twoFactorAuth.status
export const selectTwoFactorAuthErrors = state => state.account.twoFactorAuth.error
// ? Public API Keys
export const selectPublicApiKeys = state => state.account.publicApiKeys.data
export const selectPublicApiKeysStatus = state => state.account.publicApiKeys.status
export const selectPublicApiKeysErrors = state => state.account.publicApiKeys.error
// ? Session
export const selectSession = state => state.account.session.data
export const selectSessionStatus = state => state.account.session.status
export const selectSessionError = state => state.account.session.error
// ? Allowed Country
export const selectUserFromAllowedCountry = () => state => state.account.allowedCountry.data && !Device.isCordova
export const selectUserFromAllowedCountryStatus = () => state => state.account.allowedCountry.status
// ? deposit
export const selectDeposit = () => state => state.account.deposit.data
export const selectDepositStatus = () => state => state.account.deposit.status
export const selectDepositError = () => state => state.account.deposit.error
// ? withdraw
export const selectWithdraw = () => state => state.account.withdraw.data
export const selectWithdrawStatus = () => state => state.account.withdraw.status
export const selectWithdrawError = () => state => state.account.withdraw.error
// ? Cancel withdraw
export const selectCancelWithdraw = () => state => state.account.cancelWithdraw.data
export const selectCancelWithdrawStatus = () => state => state.account.cancelWithdraw.status
export const selectCancelWithdrawError = () => state => state.account.cancelWithdraw.error
// ? resend withdraw
export const selectResendWithdrawMailStatus = () => state => state.account.resendWithdrawMail.status
export const selectResendWithdrawMailError = () => state => state.account.resendWithdrawMail.error
// ? update missing info
export const selectUpdateMissingInfoStatus = () => state => state.account.updateMissingInfo.status
export const selectUpdateMissingInfoError = () => state => state.account.updateMissingInfo.error
// ? currency proproties
export const selectCurrencyProproties = () => state => state.account.currencyPropties
export const selectDeleteAccountStatus = state => state.account.delete_account.status
// ? Redeem code
export const selectRedeemCodeStatus = () => state => state.account.redeemCode.status
export const selectRedeemCodeError = () => state => state.account.redeemCode.error
// ? finance summary
export const selectFinanceSummary = () => state => state.account.financeSummary.data
// Thunks:
/**
 * Send authentication token to server in order to get the currentUser
 * data and settings. If a 401 response is received, deletes the expired credentials from localStorage.
 */
export const fetchCurrentUser =
  (silent = false) =>
  async (dispatch, getState) => {
    if (await userIsAnonymous()) {
      return
    }

    if (!silent) {
      dispatch(getCurrentUserStart())
    }

    try {
      var response = await requestService.get(`/me/`)
    } catch (err) {
      dispatch(currentUserError(err))
      if (err.status === 401) {
        deleteCredential()
      }
      return
    }
    const user = await response.json()
    dispatch(currentUserSuccess(user))
    dispatch(setActiveLanguage(user && user.settings && user.settings.language))

    const state = getState()
    if (state && state.app && !state.app.activeCurrency) {
      dispatch(setActiveCurrency(user.settings.last_currency_selected))
    }
  }

/**
 * Verify if current user is making a request from an allowed country. Don't need to
 * be an authenticated user in order to get this information.
 */
export const fetchUserInAllowedCountry = () => async dispatch => {
  dispatch(setAllowedCountryStart())
  try {
    var response = await requestService.get(`/users/am_i_from_allowed_country/`)
  } catch (err) {
    console.log(err)

    dispatch(setAllowedCountrySuccess(false))
    return
  }
  const isAllowed = await response.json()
  dispatch(setAllowedCountrySuccess(isAllowed))
}

/**
 * Optimistic update for user settings. `settings` is an object containing only the settings
 * that must be changed.
 */
export const updateCurrentUserSettings = settings => async (dispatch, getState) => {
  const account = getState().account
  const currentUser = account.currentUser && account.currentUser.data

  if (currentUser) {
    requestService.patch(`/users/${currentUser.id}/update_settings/`, settings)
    dispatch(setCurrentUserSettings(settings))
  }
}

/**
 * request public api keys
 */

// change status pending (requested)
export const requestApiKeysAccess = () => async dispatch => {
  dispatch(requestPublicApiKeysStart())
  try {
    await requestService.post('/public_api/request_access/')
  } catch (err) {
    dispatch(requestPublicApiKeysError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  dispatch(requestPublicApiKeysAccessSuccess())
  return
}

// create and return new keys (private and public) api keys => change status active
export const requestApiKeys = () => async dispatch => {
  dispatch(requestPublicApiKeysStart())
  try {
    var response = await requestService.post('/public_api/')
  } catch (err) {
    dispatch(requestPublicApiKeysError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }
  const json = await response.json()

  dispatch(requestPublicApiKeysSuccess(json))
  return
}
// return only the public key + id
export const requestPublicApiKeys = () => async dispatch => {
  dispatch(requestPublicApiKeysStart())
  try {
    var response = await requestService.post('/users/get_active_key/')
  } catch (err) {
    dispatch(requestPublicApiKeysError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }
  const json = await response.json()

  dispatch(requestPublicApiKeysSuccess(json))
  return
}
// delete the keys
export const disableApiKeys = hmac_key_id => async dispatch => {
  dispatch(requestPublicApiKeysStart())
  try {
    await requestService.post(`/public_api/${hmac_key_id}/disable/`)
  } catch (err) {
    dispatch(requestPublicApiKeysError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  dispatch(requestPublicApiKeysSuccess(null))
  return
}

/**
 * Updates current user password. The current user must be a valid entry in the store.
 */
export const updatePassword = (currentPassword, newPassword) => async (dispatch, getState) => {
  const account = getState().account
  const currentUser = account.currentUser && account.currentUser.data

  if (!currentUser) {
    throw '[Account store]: current user is not set'
  }

  dispatch(changeCurrentUserStart())
  try {
    await requestService.patch('/users/' + currentUser.id + '/update_password/', {
      current_password: currentPassword,
      new_password: newPassword,
    })
  } catch (err) {
    dispatch(currentUserError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  dispatch(currentUserSuccess(currentUser))
}

/**
 * Partially update any information from current user.
 * `data` should contain only the properties that will be updated.
 */
export const updateCurrentUser = data => async (dispatch, getState) => {
  const account = getState().account
  const currentUser = account.currentUser.data

  if (!currentUser) {
    throw '[Account store]: current user is null'
  }

  dispatch(changeCurrentUserStart())
  try {
    var response = await requestService.patch(`/users/${currentUser.id}/`, createFormData(data))
  } catch (error) {
    dispatch(currentUserError(error))
    toastService('fail', {
      title: error.error.errors && error.error.errors[0].detail,
      body: error.error.errors && error.error.errors[0].code,
    })
    return error
  }
  if (response) {
    const json = await response.json()
    dispatch(currentUserSuccess(json))
    return json
  }
}

/**
 * Proceeds with user authentication on Futuur based on email and password.
 */
export const loginEmail =
  ({ email, password, userData, authCode }) =>
  async dispatch => {
    const body = createFormData({
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      username: email,
      password: password,
      '2fa_token': authCode,
    })

    dispatch(sessionStart())

    try {
      var response = await requestService.post('/o/token/', body)
    } catch (err) {
      dispatch(sessionError(err))
      return
    }

    if (response.status === 200) {
      addParamsToGTM({ event: 'user_logged_in' })
      const data = await response.json()
      storeCredential(data)
      dispatch(sessionSuccess(data))

      if (!userData) {
        dispatch(fetchCurrentUser())
      } else {
        dispatch(currentUserSuccess(userData))
      }

      const allABTests = getAllABTests()
      const all_campains = getAllCampains()
      const res = await requestService.get(`/me/`)
      const me = await res.json()

      mixpanelService.login(
        { ...data, id: me.id },
        {
          provider: 'email',
          ...Object.fromEntries(Object.entries(allABTests).map(([key, value]) => ['ab_test_' + key, value])),
          all_campains,
        }
      )

      dispatch(parseActiveCurrency())
      dispatch(handleRegistrationCampains())
    }

    if (response.status === 401) {
      const data = await response.json()

      if (data && data.error === 'missing_2fa_token') {
        console.log('show 2fa')
      }
    }
  }

/**
 * Try to authenticate a user using a social network authentication token provided by their
 * own OAuth methods.
 */
export const loginSocialNetwork = bodyData => async dispatch => {
  dispatch(sessionStart())
  try {
    var response = await requestService.post('/social_sign_up/', bodyData)
  } catch (err) {
    dispatch(sessionError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  const data = await response.json()
  storeCredential(data.oauth)
  dispatch(sessionSuccess(data))
  await dispatch(fetchCurrentUser())
  dispatch(parseActiveCurrency())
  const allABTests = getAllABTests()
  const all_campains = getAllCampains()

  if (response.status === 200) {
    addParamsToGTM({ event: 'user_logged_in' })
    mixpanelService.login(data, {
      provider: bodyData.mixpanel_provider,
      ...Object.fromEntries(Object.entries(allABTests).map(([key, value]) => ['ab_test_' + key, value])),
      all_campains,
    })
    dispatch(handleRegistrationCampains())
  }

  if (response.status === 201) {
    mixpanelService.register(data, {
      provider: bodyData.mixpanel_provider,
      ...Object.fromEntries(Object.entries(allABTests).map(([key, value]) => ['ab_test_' + key, value])),
      all_campains,
    })
    dispatch(handleRegistrationCampains())
    analyticsService.trackRegistration(
      {
        id: data.id,
        provider: bodyData.provider,
      },
      bodyData.registerMethod
    )
  }

  return response
}

/**
 * Try to authenticate a user using Google as OAuth provider
 */
export const loginGoogle = access_token => async dispatch => {
  const body = {
    mixpanel_provider: 'google',
    provider: 'google-oauth2',
    access_token: access_token,
    registerMethod: 'google',
  }

  dispatch(loginSocialNetwork(body))
}
/**
 * Exchanging auth_code for access_token
 */
export const exchangeAuthCodeforAccessToken = authCode => async dispatch => {
  const body = {
    client_id: process.env.REACT_APP_GOOGLE_APP_ID,
    client_secret: process.env.REACT_APP_GOOGLE_REACT_APP_CLIENT_SECRET,
    code: authCode,
    grant_type: 'authorization_code',
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  }
  try {
    var response = await fetch('https://oauth2.googleapis.com/token', requestOptions).then(response => response.json())
  } catch (err) {
    dispatch(sessionError(err))
    throw err
  }
  return response
}

/**
 * Try to authenticate a user using Facebook as OAuth provider
 */
export const loginFacebook = (fbToken, attribution) => async dispatch => {
  const body = {
    mixpanel_provider: 'facebook',
    provider: 'facebook',
    access_token: fbToken,
    language: global.navigator.language,
    attribution: attribution,
    registerMethod: 'facebook',
  }
  dispatch(loginSocialNetwork(body))
}

/**
 * Revoke the authentication token from current user and removes all the stored credentials related
 * to Futuur app and Flarum
 */
export const logout = () => async dispatch => {
  if (await userIsAnonymous()) {
    return
  }

  const credential = await getCredential()
  const payload = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    token: credential.accessToken,
  }

  dispatch(resetCurrentUser())
  try {
    requestService.post('/o/revoke_token/', createFormData(payload))
    deleteCredential()
    //deleteCurrencyPreferences()
    mixpanelService.logout()
    Sentry.clearUserContext()
  } catch {
    console.log('NetworkError')
  }
}

/**
 * Sometimes a user is authenticated but their token needs to be renewed to extend its
 * expiration date.
 */
export const refreshToken = () => async dispatch => {
  const credential = await getCredential()
  const payload = {
    grant_type: 'refresh_token',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    refresh_token: credential.refreshToken,
  }

  try {
    var request = await requestService.prepareRequest('/o/token/', {
      method: 'POST',
      body: createFormData(payload),
    })
    var response = await global.fetch(request)
  } catch (err) {
    if (err.status == 401) {
      dispatch(resetCurrentUser())
    }
    return
  }

  const data = await response.json()
  storeCredential(data)
  dispatch(sessionSuccess(data))
  dispatch(fetchCurrentUser())
}

/**
 * Creates a new Futuur forecaster providing email and password. Username, profile picture and
 * other information should be set using the update routine.
 */
export const registerEmail = data => async dispatch => {
  const { email, password, inviteKey, username, picture } = data
  if (!(await userIsAnonymous())) {
    return
  }

  dispatch(sessionStart())

  const payload = {
    email,
    password,
    username,
    picture,
    invite_key: inviteKey,
    language: global.navigator.language,
  }
  if (!payload.picture) delete payload.picture

  try {
    const response = await requestService.post('/users/', createFormData(payload))
    const json = await response.json()
    dispatch(storeActiveCurrency('OOM'))

    dispatch(loginEmail({ email, password, userData: json })).then(async () => {
      const res = await requestService.get(`/me/`)
      const me = await res.json()
      const user = { id: me.id, email, language: payload.language }
      const allABTests = getAllABTests()
      const all_campains = getAllCampains()

      dispatch(sessionSuccess(json))
      dispatch(setCurrentUserSettings(json))
      mixpanelService.register(user, {
        provider: 'email',
        ...Object.fromEntries(Object.entries(allABTests).map(([key, value]) => ['ab_test_' + key, value])),
        all_campains,
      })
      analyticsService.trackRegistration({ email }, 'email')
      dispatch(handleRegistrationCampains()())
    })
  } catch (error) {
    dispatch(sessionError(error))
    return
  }
}

export const addCampain = campainCode => {
  let campains = JSON.parse(localStorage.getItem('campains') || '[]')
  if (campains.indexOf(campainCode) === -1) {
    campains.push(campainCode)
    localStorage.setItem('campains', JSON.stringify(campains))
  }
}

export const handleRegistrationCampains = () => dispatch => {
  let campains = JSON.parse(localStorage.getItem('campains') || '[]') || []

  if (campains && campains.length) {
    campains.forEach(async campainCode => {
      await dispatch(enrollIntoCampain(campainCode))
      campains = campains.filter(c => c !== campainCode)
      localStorage.setItem('campains', JSON.stringify(campains))

      if (campainCode === '5f59c012cbf4c157e070') {
        // custom event for brazilians
        toastService('success', {
          title:
            'Parabens, você foi inscrito com successo!  Agora é só fazer um depósito dentro de 30 dias, e você receberá seu bônus adicional de 100% até um valor de $10 USD.',
        })
      }
    })
  }
}
export const fetchCryptoAddress = (currencyId, network) => async dispatch => {
  dispatch(getWalletAddressLoading({ currencyId }))
  try {
    var response = await requestService.get(
      `/finance/get_crypto_address_for_deposit/?crypto=${currencyId}` + (network && `&network=${network}`)
    )
  } catch (err) {
    dispatch(getWalletAddressError({ error: err.error }))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  const json = await response.json()
  dispatch(getWalletAddressSuccess({ currencyId, address: json.address }))
}
export const requestDeposit = data => async dispatch => {
  dispatch(requestDepositStart())
  try {
    var response = await requestService.post(`/deposits/request/`, data)
  } catch (err) {
    dispatch(requestDepositError({ error: err.error }))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  const json = await response.json()
  dispatch(requestDepositSuccess(json))
}

export const updateMissingInfoRequest = data => async (dispatch, getState) => {
  const account = getState().account
  const currentUser = account.currentUser.data
  dispatch(updateMissingInfoStart())
  try {
    var response = await requestService.put(`/users/${currentUser.id}/update_kyc_data/`, data)
  } catch (err) {
    dispatch(updateMissingInfoError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  await dispatch(fetchCurrentUser(true))
  dispatch(updateMissingInfoSuccess(await response.json()))
}
export const getCountryFromIp = () => async dispatch => {
  try {
    var response = await requestService.get(`/users/get_country_from_ip/`)
  } catch (err) {
    dispatch(updateMissingInfoError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }
  return response.json()
}
export const cancelWithdrawRequest = requestId => async dispatch => {
  dispatch(cancelWithdrawStart())
  try {
    var response = await requestService.post(`/withdrawals/${requestId}/cancel/`)
  } catch (err) {
    dispatch(cancelWithdrawError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }

  await dispatch(fetchCurrentUser(true))
  dispatch(cancelWithdrawSuccess(await response.json()))
}
export const resendWithdrawMail = requestId => async dispatch => {
  dispatch(resendWithdrawMailStart())
  try {
    var response = await requestService.post(`/withdrawals/${requestId}/resend_confirmation_email/`, {
      withdrawal_request_id: requestId,
    })
  } catch (error) {
    dispatch(resendWithdrawMailError(error))
    throw error
  }

  await dispatch(fetchCurrentUser(true))
  dispatch(resendWithdrawMailSuccess(await response.json()))
}
export const getUserDocumentOnDeposit = () => async dispatch => {
  var response = await requestService.get(`/finance/get_user_documents_on_deposit/`)

  dispatch(setUserDocumentOnDeposit(await response.json()))
}
export const getUserDocumentOnWithdraw = () => async dispatch => {
  var response = await requestService.get(`/finance/get_user_documents_on_withdrawal/`)

  dispatch(setUserDocumentOnWithdraw(await response.json()))
}
export const requestWithdraw = options => async dispatch => {
  dispatch(requestWithdrawStart())
  try {
    var response = await requestService.post(`/withdrawals/request/`, options)
  } catch (error) {
    dispatch(requestWithdrawError(error))
    throw error
  }
  const json = await response.json()

  await dispatch(fetchCurrentUser(true))
  if (options.realMoneyType === 'FIAT' || options.withdrawal_method === 'PIX') {
    dispatch(getAstropayResponseSuccess({ response: json.astropay_response }))
    dispatch(requestWithdrawSuccess(json))
  } else {
    dispatch(requestWithdrawSuccess(json))
  }
}

export const verifyPassword = (token, password) => async dispatch => {
  try {
    const response = await requestService.post(`/password_reset/`, {
      password_reset_token: token,
      new_password: password,
    })
    return response
  } catch (err) {
    dispatch(requestWithdrawError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    throw err
  }
}

export const getKycAuthToken = async () => {
  var response = await requestService.get(`/users/get_sumsub_access_token`)
  const json = await response.json()
  return json.token
}
export const getTotpDevices = async () => {
  var response = await requestService.post(`/totp_devices/`)
  const json = await response.json()
  return json
}
export const enableTwoFactorAuth = code => async dispatch => {
  dispatch(requestTwoFactorAuthStart())
  try {
    var response = await requestService.post(`/users/enable_2fa/`, { '2fa_token': code })
  } catch (error) {
    dispatch(requestTwoFactorAuthError(error))
    throw error
  }

  const json = await response.json()
  dispatch(requestTwoFactorAuthSuccess(json))
  dispatch(fetchCurrentUser())

  toastService('success', {
    title: ' 2FA enabled',
    body: 'Some actions such as login will require 2FA from now on',
  })
}
export const disableTwoFactorAuth = () => async dispatch => {
  dispatch(requestTwoFactorAuthStart())
  try {
    var response = await requestService.post(`/users/disable_2fa/`)
  } catch (error) {
    dispatch(requestTwoFactorAuthError(error))
    throw error
  }

  const json = await response.json()
  dispatch(requestTwoFactorAuthSuccess(json))
  dispatch(fetchCurrentUser())

  toastService('success', {
    title: ' 2FA disabled',
    body: 'You can enable it again anytime.',
  })
}
export const isValidEmailForSignup = async email => {
  var response = await requestService.get(`/users/is_valid_email_for_signup/?email=${encodeURIComponent(email)}`)
  const json = await response.json()
  return json
}
export const check_username = async username => {
  var response = await requestService.get(`/users/check_username/?username=${username}`)
  const json = await response.json()
  return json
}
export const fetchCurrenciesProproties = () => async dispatch => {
  var response = await requestService.get(`/finance/currency_properties/`)
  const json = await response.json()
  dispatch(setCurrencyPropties(json))
  return json.token
}

export const requestCurrentUserEmailConfirmation = () => async () => {
  return await requestService.post(`/users/send_activation_email/`)
}
export const enrollIntoCampain = code => async dispatch => {
  dispatch(sessionStart())
  try {
    await requestService.post(`/campaigns/${code}/enroll/`)
  } catch (err) {
    dispatch(sessionError(err))
    toastService('fail', {
      title: err.errors && err.errors[0].detail,
      body: err.errors && err.errors[0].code,
    })
    return
  }
  dispatch(sessionSuccess())
}
export const reedeemPromoCode = code => async dispatch => {
  dispatch(redeemCodeStart())
  try {
    await requestService.post(`/campaigns/redeem_promo_code/`, { code })
  } catch ({ error }) {
    dispatch(redeemCodeError(error))

    toastService('fail', {
      title: error.errors && error.errors[0].detail,
      body: error.errors && error.errors[0].code,
    })
    return
  }
  dispatch(redeemCodeSuccess())
}
export const fetchFinanceSummary = () => async dispatch => {
  dispatch(financeSummaryStart())
  try {
    const response = await requestService.get(`/finance/summary/`)
    const json = await response.json()
    dispatch(financeSummarySuccess(json))
  } catch ({ error }) {
    dispatch(financeSummaryError(error))

    toastService('fail', {
      title: error.errors && error.errors[0].detail,
      body: error.errors && error.errors[0].code,
    })
    return
  }
}

export const deleteAccount = payload => async dispatch => {
  await dispatch(deleteAccountStart())

  try {
    const response = requestService.post(`/users/inactivate/`, payload)
    var data = await response.json()

    await dispatch(deleteAccountSuccess(data))
  } catch (err) {
    dispatch(deleteAccountError(err))
  }
}
