// import { GoogleLogin } from '@react-oauth/google'

const GoogleAuthCodeLogin = () => {
  // GoogleLogin({
  //   flow: 'auth-code', // Enables Auth Code Flow
  //   client_id: process.env.REACT_APP_GOOGLE_APP_ID,
  //   onSuccess: response => {
  //     console.log('Authorization Code:', response)

  //     // Send `response.code` to your backend to exchange for an access token
  //   },
  //   onError: () => console.log('Login failed'),
  // })
}

export default GoogleAuthCodeLogin
